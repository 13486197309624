<template>
	<span>
		<span>
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="50" viewBox="0 0 100 50">
			  <image id="_bg_" data-name="&lt;bg&gt;" x="50" width="50" height="50" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAD0ElEQVRoga1aS3LbMAwFZXXpC+Q4vXUP1C678S6Ji45l2gKJ90BQ9pvJSALx5QcE6ZS/v37+VpHzP1nkqotcpf7paXt+m+eXnORLT9v79/a+Vtq60e/vP7b3z+173b4/a/un1OfGs9bvtfI+6KeqazV617v9ynd92r89N18vq4h8SA91lBy0OLZdVWm+j5ogOC8icsFtyJmHOxmURlAbRSrurYmspCwZjsviSCQIdb3IZFT00QZZIHGXIRiN4OIos3haiB25s4x4dNczOfe6QLyhQt7zdhhnPGHBcqNQHwg3dQzIm9z8j+z7aW4CYUK78Rxslop1cqPaWCyE3oqHI8LBJhxGn3K1yqmLpHW6d9aCjkg2AKugVaZPHm+GOHxgXXtNdyWLJXm2OWDp7LQ8Mn33dzIiRCn2NJAjepr2flyBEbXasE4SCPK5gOWG+GJ6q4+hDFjbckeiQGagiZ05E9qoUyIb04s9MojMPzKUd8rqnF+bfUiDQHgPpKAyCL7baww/Ay4yEyOCMoR1BHMzZ7yGVt1sstjRBDIwk+RhO3rsFJbB8kjTWxY7Vj3brnzeQLTVBah+WY/mXI5xfNN1Ul1VsHDOGLlp2JXqsHIpDUvananF3h1VuXJwdAVjt6fempo9y5OvaGTPwwWCOk3TGkv3RDq99179fPZygUQqC22J3aIy7sKBNCmgdXgpa7XTiCQJSOwbiqEFAdnW0nJOB0L9onxIgo0m5kY88GAVC3OjYxQg7ggEhM8Ua7aIfHlD9EVg1mG/p8xUFmpm49tuUTwfn1j7eR0hDr4I2I6qSBBIrNTzRQWj2Rq1bxnrD3lqYxAIR6i4c8IFVvoP0wFTc6slv+E6yNOPVFN7tcKlecvbqt+MtX5XA3dBVBagG0AaCCorjl8W4cJw/6bbnoP2S7KCBvKSs9VSf17fW+zTt4v301miZ/ZIsDWADUd6+qq3cckJDLIUsT91ZrdqcEXMUjDqQy/VS/jlw3WQQJBAlEZTDRguHVvH8yCBeGWuR5rTXTYZ+LJkTEfwh4swkB7IFMPcprnDXusJ7EwB/INASKZzSjjQmSMn6QH2HQMSCOZmEwPlIzbdlNCzcHd4FeA6aJxiW8e8PLpVcGsMyEcXFyOQEZlT2KZiTYqxxB6wGHJ/KJ6+MuUgqRNuOGifqZJ2NPOxvVKi+BTorQFPII07KGaqP8oydDhzgUQK0zxFqMNtkMfnALx8oEZftMLdzGSuOZ9ugZydB/BrAqXX025zXC+6ddllAn/Ot//X+tMGwxEH1u/JxrlQDP6Oy4tVxCvl8h9gSXWpGdX0AQAAAABJRU5ErkJggg=="/>
			  <image id="fi" x="64" y="9" width="22" height="32" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAgCAYAAAAWl4iLAAACA0lEQVRIie2WP0iVYRTGf9W1MkX6h4tRQyToVBItgrUmOgTNd2lq0CVoaG0U/IctkTgEoWMIokND0FBjiToU9B+iQMtsCCnl6PPq4Xvfe/vuvU3RAx/f9z3nnOe957znnvfbQz50AZ1AG3AESMVtAEvAIPApsmZQBBYUlPcy//rUyujXTQEter8LzAEvge9aIAvTugRMAJORFeh3v+xaZP0zFlML3xT5BGiqQvQk8Fu13kG3RB9F7nAeGFPACvA1cX1zmbaHGheAdRkPO8GDwAPgit7XgI+pVNnduNvAi0COynDBOTY6kRGlWRH2udU8nou/XEWtt9ArgauOK4rri7wrwD2J+C5YUT1rwofMZrTofbgWUeuGL8BnxzUD74FXJTZsVa1maACORR7bvRwhtOBMidkw4AKKCbtdPwqR7G6P3gGeOv4AcEvTLaBe93HgnZ77S2RRFrbofedwXdxxxz02bm85lRwItTydzSJVigDr73OZUhh+Rp4JlBO+AVyM2O0ezyX8FpgHesSdBR4CQ9r1LFYjJoGCevWXMzWIW3M7XTHC5i27wHXd66oVxQn/dfwX/keFS30l1Swc+rlVo9Jmhf15ciHMCgsOOKSHo7p3AGeAExrydrIEhLkbYgzmu3X0n9JpYXPUYCWwg3UWeK1P0vAx88xlEjK2eTzthtN+4M0m912DLx888qsAAAAASUVORK5CYII="/>
			  <image id="w" x="6" y="9" width="38" height="32" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAgCAYAAAB+ZAqzAAAD6ElEQVRYhb2YTWhdRRTHf2fee0laa6BINopiEawbQehaKBa3pYig4MK1gooIUhG6E8VFEYu77ovgwroQuongqhtFl+om0lDREpQY8/KS3Dkyd2benXtn7stLWvKH4d53vu7/zMeZmSd/33rhHQsv7uuQPYbsqm8THbFTt4Xpc1sXGdsFxrrItoanXfTy+j3YOJ31z+g/0QUmIW79DYbs6+DqSKtVg6JweiKj50HsSPdvD4FLwHllNhTp6Nu/tSDrovCNVYFVi7AnwxXgoqD/7otZGyq8DHJKEkctB+mQmG3T59NKStiodSou8ZOg66BjRU4MBTbUtyyATptMBe7dtQG2frqhqjCJr0D9oQKR4OuNBcTbuVhGd/+cyOiWIksja/8aZqkdEvZ+AzTU/8C3mr4j9gXwWmbZjwlwB/SKwLfiA70FvA485X5qMi96h9vbvIFyo2TniP0TmWba8mReBs6BnElk50L7TaGKvnHYsrBT6HYmSoh9GNpMaD1s4tpjwDqw18xLqUfUiH06ZdFLSOJ87YdpgkihNXL3w+IqjqyQx7Wq9feWZLrKSnHDYlLfekakhskkB2Mww2KW7lA4NLG80MYxK8iPlxib4XkiES+H53+ZwxHhJv8jwEMHExIMlgF6Zhe5A9xLdGuCrldqzlpknDn3Y6MvGUfsS4UL9S+ZsZSCzmC3BH2YsCAC3q8wVw4mJe1X5V3gs8zMEVP4HPg+03QDhXmkyF0jFk1KfoW4bWlH4ANgofEtzbuW7LtMHYkB34TW4lDeM6NAWr0rjd0nqrl/N2ajk95hMoUsCujLfh6U/EqyNlyPXQSeyzT9uCtwvasNOV9uhnIu3AR+Lhk6Ym8TJ38ro6byd7p6q8Jcr4cz6KWurLq0i3zszZs9Mh+kNBPdBO0l9kqrXPRG8rCYsxXyO6jrnRtB/KnBvmrQZ1CylVkM6RPY8IlpZpMcFMXvYemeFt9DD7gDYaWyKOgTwEoS50lFHjfoL4TNPt0j6eyXJDH7ZttR9srlMNRjmjW1GXQHFup5cRRiLcxzCTkuYvVBMCWkHd2DoDk3sThJBe6Fs1T6/TqORXaKp48jwG1JHwEvede+oM1ENWKXw8QdJdqaWIX51fdae40VV6XH5VDLMrhycRp4NNO0SKWhxV1GfgTWolzhB0WejZeR/gSz5E9m6gBH7M3QZgXoaNSXDgYM6i18eA24RqytM7qIOS/K6U21qdTpmV1p3XbipI/vzTbs62D0LW3i8yQdEQ+KpzLN8cAdFLdKX3LEvkI437p2Fa5XPeWh+DuVt484me17wNU+Yl+jD+6s3iAdrua/jw5+KroC/wMvL6Nsacn0sQAAAABJRU5ErkJggg=="/>
			</svg>
		</span>
	</span>
</template>

<style>

</style>
<script>
	import Vue from 'vue'
	export default {
		data() {
			return {

			}
		},
		props: [],
		created() {

		},
		mounted() {

		},
		computed: {

		},
		watch: {

		},
		methods: {

		},
	}
</script>
