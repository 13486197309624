<template>
	<div>
        <div class="chart-container pt-12">
            <technical-analysis-line-chart></technical-analysis-line-chart>
        </div>
	</div>
</template>
<script>
    import config from '@/config.js';
    import TechnicalAnalysisLineChart from '@/highcharts/TechnicalAnalysisLineChart.vue';
    export default {
        components: { TechnicalAnalysisLineChart },
        data(){
            return {
                option:{
                    width: innerWidth,
                    height: innerHeight
                }
            }
        },
        created(){
            this.isLogin();
        },
        mounted(){
            this.onResize();
            window.addEventListener('resize', this.onResize)
            // this.getCandles('LUNA');
        },
        computed: {
            
        },
        watch:{

        },
        methods: {
            // 是否登录
            isLogin() {
                if (!this.user.token) {
                this.$router.push({
                    name: 'signIn'
                });
                }
            },
            // 调整图表尺寸
            onResize() {
                this.option.width = window.innerWidth
                this.option.height = window.innerHeight - 55
            },
            // 查询K线数据
            getCandles(currency){
                let api = config.baseUrl + '/cmc/public/candle?currency=' + currency;
                this.$http.get(api).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        console.log(this.chart);
                        this.chart.data.chart.data = data.data;
                    }
                });
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
        },
    }
</script>
<style>
.chart-container{
    height: 800px;
}
</style>