<template>
<div ref="screenshot">
    <div class="mt-5 mx-3 fs-12">
        <v-row dense>
            <v-col cols="5" md="1" sm="5">
                <v-select class="fs-12 mr-3" dense v-model="currency" :items="tradingCurrencies" :label="$t('log.currency')"></v-select>
            </v-col>
            <v-col cols="2" md="10" sm="2">
            </v-col>
            <v-col cols="5" md="1" sm="5" class="text-right">
                <span v-show="!isTakingScreenshot" class="mr-n2">
                    <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.refresh')" @click="getLineCharts">mdi-refresh</v-icon>
                    <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.areaspline')" @click="areasplineType = !areasplineType">mdi-chart-areaspline</v-icon>
                    <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
                    <v-icon size="18" class="lineblue--text mr-1" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
                </span>
            </v-col>
        </v-row>
    </div>
    <div :id="id" style="height: 780px" v-if="responsive == 'PC'"></div>
    <div :id="id" style="height: 540px" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
    <div class="fs-12 text-right mr-3">
        <span class="pointer" :class="[xAxisLogType ? 'lineblue--text' :'grey--text']" @click="xAxisLogType = !xAxisLogType">X Axis Log</span>
        <span class="pointer ml-5" :class="[yAxisLogType ? 'lineblue--text' :'grey--text']" @click="yAxisLogType = !yAxisLogType">Y Axis Log</span>
    </div>
    <div class="chart-credits mt-5" v-show="isTakingScreenshot">
        <fi-logo></fi-logo>
        <div class="chart-credits-text">iob.fi Charts</div>
    </div>
    <v-dialog v-model="screenshotDialog" persistent width="450">
      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="black--text">{{$t("common.imageURL")}}</span>
          <v-spacer></v-spacer>
          <v-btn light icon @click="screenshotDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-show="!uploadBase64Loading" class="mt-3">
            <v-text-field id="imageHttpUrl" v-model="imageHttpUrl" single-line outlined disabled hide-details class="mt-3 mb-3"></v-text-field>
            <v-btn text small class="text-none lineblue--text pl-0" @click="saveImage()">{{$t("common.save")}}</v-btn>
            <v-btn text small class="text-none lineblue--text" v-clipboard:copy="imageHttpUrl" v-clipboard:success="copySuccess" v-clipboard:error="copyError">{{$t("common.copy")}}</v-btn>
            <v-btn text small class="text-none lineblue--text" :href="imageHttpUrl" target="_black">{{$t("common.open")}}</v-btn>
          </div>
          <div v-show="uploadBase64Loading" class="mt-3 text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
import config from '@/config.js';
import FiLogo from '@/components/common/FiLogo.vue';
import Highcharts from 'highcharts/highstock';
import { mapGetters } from "vuex";
import html2canvas from 'html2canvas';
export default {
    data() {
        return {
            day: 365,
            intervalList: [
                { text: '1D', value: 'day' },
                { text: '1W', value: 'week' },
                { text: '1M', value: 'month' },
            ],
            interval: { text: '1W', value: 'week' },
            currency: 'BTC',
            id: 'TechnicalAnalysisLineChart',
            // 图片弹窗
            screenshotDialog: false,
            // candleChart图片base64地址
            imageBase64Url: null,
            // candleChart图片http地址
            imageHttpUrl: null,
            // 上传图片加载中
            uploadBase64Loading: false,
            // 是否正在截图
            isTakingScreenshot: false,
            chart: null,
            loading: false,
            // 区域线条类型
            areasplineType: false,
            // x轴log
            xAxisLogType: false,
            // y轴log
            yAxisLogType: false,
            option: {
                // 图表类型
                chart: {
                    backgroundColor: '',
                    renderTo: 'DefyNavLineChart',
                    type: 'line',
                    // 鼠标拖拽
                    zoomType: 'x'
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#9E9E9E'
                    },
                },
                subtitle: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#999999'
                    },
                },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 1,
                    tickInterval: 200,
                    tickWidth: 1,
                    tickPosition: 'inside',
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: true
                    }
                },
                // y轴
                yAxis: {
                    // type: 'logarithmic',
                    title: {
                        text: null
                    },
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true,
                    offset: 20,
                    labels: {
                        align: "left",
                        x: -10,
                        formatter: function () {
                            return this.value;
                        }
                    },
                },
                // 鼠标悬浮提示框
                tooltip: {
                    // valueDecimals: 4,
                    valuePrefix: '$',
                    valueSuffix: '',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                    // type: 'area',
                    name: 'Price',
                    data: [],
                    color: {
                           linearGradient: {
                             x1: 0,
                             x2: 0,
                             y1: 0,
                             y2: 1
                           },
                           stops: [
                             [0, '#4CAF50'],
                             [1, '#FEE108']
                           ]
                         },
                    lineWidth: 3
                }],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                // 图例导航
                legend: {
                    enabled: false,
                    align: 'left',
                    itemStyle: {
                        color: '#999999'
                    },
                    itemHoverStyle: {
                        color: '#999999'
                    },
                    itemHiddenStyle: {
                        color: '#606063'
                    },
                    title: {
                        style: {
                            color: '#C0C0C0',
                            fontFamily: '\'Lexend Deca\', sans-serif'
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: 'black',
                        theme: {
                            fill: '#EEEEEE',
                        },
                    }
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    },
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, '#4CAF50'],
                                [1, '#FEE108']
                            ]
                        },
                        marker: {
                            radius: 2
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },
                // 版权信息
                credits: {
                    enabled: true,
                    href: config.domainName,
                    text: '',
                    className: 'a',
                    position: {
                        x: -12,
                        y: -25
                    },
                    style: {
                        fontSize: '12px',
                        color: 'white',
                        target: '_black'
                    }
                },
                exporting: {
                    enabled: false,
                    filename: 'Technical-Analysis-Chart',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
        }
    },
    props: {

    },
    watch: {
        currency(val) {
            if (this.currency) {
                // 重新查询
                this.getLineCharts();
            }
        },
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
        areasplineType(val){
            if(val){
                this.option.series[0].type = 'area';
            } else {
                this.option.series[0].type = 'spline';
            }
            this.renderChart();
        },
        xAxisLogType(val){
            if(val){
                this.option.xAxis.type = 'logarithmic';
                this.option.xAxis.labels.enabled = false;
            } else {
                this.option.xAxis.type = 'datetime';
                this.option.xAxis.labels.enabled = true;
            }
            this.renderChart();
        },
        yAxisLogType(val){
            if(val){
                this.option.yAxis.type = 'logarithmic';
            } else {
                this.option.yAxis.type = '';
            }
            this.renderChart();
        },
    },
    created() {
        
    },
    mounted() {
        this.getLineCharts();
        Highcharts.setOptions({
            lang: {
                noData: "",
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
        this.renderChart();
    },
    computed: {
        ...mapGetters(['responsive', 'darkMode', 'currencies']),
        tradingCurrencies(){
            return this.currencies.filter(currency => currency != "USD" && currency != "USDT")
        }
    },
    components: {
        FiLogo
    },
    methods: {
        getLineCharts(){
            this.loading = true;
            this.option.series[0].data = [];
            this.option.xAxis.categories = [];
            let api = config.baseUrl + "/cmc/public/chart/line?currency=" + this.currency;
            this.$http.get(api).then(res => {
                let data = res.data;
                if (data.code == 200) {
                    this.option.series[0].data = data.result.values;
                    // this.option.xAxis.categories = data.result.date;
					let temp = [];
                    data.result.dates.forEach(date => {
                        temp.push(this.CommonJS.formatBritishDate(date));
                        // temp.push(date.substring(0, 7));
                    });
					this.option.xAxis.categories = temp;
                    // 重新渲染图表
                    this.renderChart();
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.noData();
                }
            })
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
        },
        // 无数据
        noData() {
            Highcharts.setOptions({
                lang: {
                    noData: this.$t('highcharts.noData'),
                    contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                    printChart: this.$t('highcharts.printChart'),
                    resetZoom: this.$t('highcharts.resetZoom'),
                    resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                    downloadPNG: this.$t('highcharts.downloadPNG'),
                    downloadJPEG: this.$t('highcharts.downloadJPEG'),
                    downloadPDF: this.$t('highcharts.downloadPDF'),
                    downloadSVG: this.$t('highcharts.downloadSVG')
                }
            });
            this.renderChart();
        },
        // 生成图片
        generateImage() {
            let pageYoffset = window.pageYoffset;
            let documentElementScrollTop = document.documentElement.scrollTop;
            let scrollTop = document.body.scrollTop;
            window.pageYoffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            // 正在截图
            this.isTakingScreenshot = true;
            let _this = this;
            // 延时操作，不要问为什么，不延迟不可以
            setTimeout(function() {
              // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
              html2canvas(_this.$refs.screenshot, {
                backgroundColor: _this.darkMode ? '#303030' : '#EEEEEE',
                useCORS: true
              }).then((canvas) => {
                let url = canvas.toDataURL('image/png');
                _this.imageBase64Url = url;
                // 生成截图完毕
                _this.isTakingScreenshot = false;
                window.pageYoffset = pageYoffset;
                document.documentElement.scrollTop = documentElementScrollTop;
                document.body.scrollTop = scrollTop;
                _this.uploadBase64Loading = true;
                _this.screenshotDialog = true;
                // 把生成的base64位图片上传到服务器,生成在线图片地址
                _this.uploadBase64();
              })
            }, 1);
        },
        // 上传base64
        uploadBase64(){
            this.uploadBase64Loading = true;
            let api = config.baseUrl + '/upload/public/base64';
            let param = { base64: this.imageBase64Url };
            this.$http.post(api, param).then(response => {
              let data = response.data;
              this.uploadBase64Loading = false;
              if (data.code == 200) {
                this.imageHttpUrl = data.result;
              } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
              }
            }, error => {
              this.uploadBase64Loading = false;
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            });
        },
        // 点击下载图片
        saveImage(){
            let a = document.createElement('a');
            // 设置图片地址
            a.href = this.imageBase64Url;
            // 设定下载名称
            a.download = 'Technical-Analysis-Chart-' + this.currency;
            // 点击触发下载
            a.click();
        },
        // 复制成功回调函数
        copySuccess(){
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
        },
        // 复制失败回调函数
        copyError(){
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
        },
        //全屏
        toggleFullscreen() {
            if (this.chart.fullscreen) {
              this.chart.fullscreen.toggle();
            }
        },
    }
}
</script>
<style scoped lang="scss">
.chart-credits{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .chart-credits-text{
        margin-left: 15px;
        font-size: 18px;
    }
}
</style>